<script setup>
import PageHeader from "@/components/page-header.vue";
import NewsList from "./List.vue"

const items = [
  {
    text: "Home",
    href: "/",
  },
  {
    text: "Inter Rail (interrail.uz)",
    href: "/inter_rail_uz",
  },
  {
    text: "News",
    active: true,
  }
]
</script>

<template>
  <PageHeader title="News" :items="items"/>
  <NewsList/>
</template>

<style scoped>

</style>