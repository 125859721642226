<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Delete",
  emits: ['deleted'],
  data() {
    return {
      showModal: false,
      post: null
    }
  },
  methods: {
    openModal(post) {
      this.post = post
      this.showModal = true
    },
    async deletePost() {
      try {
        await axios.delete(`/website/posts/${this.post.id}/delete/`)
        await Swal.fire({
          icon: "success",
          title: "Successfully deleted post",
          showConfirmButton: false,
          timer: 5000,
        }).then(() => {
          this.showModal = false;
          this.$emit('deleted');
          setTimeout(() => {
            this.post = null;
          }, 500);
        })
      } catch {
        await Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
        })
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Deleting post" hide-footer centered size="md">
    <template v-if="post">
      <h4 class="mb-3">{{ post.title }}</h4>

      <div class="border-top pt-3">
        <h5 class="mb-3 text-danger">Are you sure you want to delete this post?</h5>

        <div class="d-flex gap-3">
          <b-button @click="deletePost()" variant="danger">Yes, delete</b-button>
          <b-button @click="showModal = false" variant="success">No, keep</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

</style>