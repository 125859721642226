<script>

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Update",
  emits: ["updated"],
  data() {
    return {
      showModal: false,
      editor: ClassicEditor,

      step: 1,
      images: [],

      post_id: null,
      title_en: '',
      title_ru: '',
      content_en: '',
      content_ru: '',

      is_loading: false,
    }
  },
  components: {
    ckeditor: CKEditor.component,
  },
  methods: {
    openModal(post) {
      this.post_id = post.id;
      this.title_en = post.title;
      this.title_ru = post.title_ru;
      this.content_en = post.content;
      this.content_ru = post.content_ru;

      this.images = (post.images || []).map(image => {
        return {
          id: image.id,
          url: process.env.VUE_APP_ORDER_URL + image.image,
          is_new: false,
          is_deleted: false
        }
      })

      this.showModal = true
    },
    isEven(num) {
      return num % 2 === 0;
    },
    async handleFileUpload(event) {
      const files = Array.from(event.target.files);
      const images = await Promise.all(files.map(async (file) => {
        return {
          name: file.name,
          file: file,
          url: URL.createObjectURL(file),
          is_new: true,
          is_deleted: false
        };
      }));

      this.images = [...this.images, ...images].filter(img => img);

      let input = document.getElementById('add_news_images')
      input.value = '';
    },
    deleteImage(img_index) {
      const filteredImages = this.images.filter(i => !i.is_deleted);

      if (img_index >= 0 && img_index < filteredImages.length) {
        const itemToDelete = filteredImages[img_index];
        const originalIndex = this.images.findIndex(i => i === itemToDelete);

        if (originalIndex !== -1) {
          this.images[originalIndex] = { ...this.images[originalIndex], is_deleted: true };
        }
      }
    },
    resetForm() {
      this.step = 1;
      this.images = [];
      this.title_en = '';
      this.title_ru = '';
      this.content_en = '';
      this.content_ru = '';
    },
    async submitForm() {
      try {

        if (!this.title_en || !this.title_ru || !this.content_en || !this.content_ru) {
          await Swal.fire({
            title: 'Oops...',
            text: "Please fill in all required fields",
            icon: "warning",
            showConfirmButton: true,
          })
          return;
        }

        if (this.images.length === 0) {
          await Swal.fire({
            title: 'Oops...',
            text: "Please upload at least one image",
            icon: "warning",
            showConfirmButton: true,
          })
          return;
        }

        const formData = new FormData();

        formData.append('title', this.title_en);
        formData.append('title_ru', this.title_ru);
        formData.append('content', this.content_en);
        formData.append('content_ru', this.content_ru);

        this.images.filter(i => i.is_new === true).forEach((image) => {
          formData.append(`images`, image.file);
        });

        this.images.filter(i => i.is_new === false && i.is_deleted).forEach((image) => {
          formData.append(`deleted_image_ids`, image.id);
        });

        this.is_loading = true;

        await axios.put(`/website/posts/${this.post_id}/update/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.showModal = false;
        this.is_loading = false;
        this.resetForm()
        await Swal.fire({
          title: 'Success',
          text: "Successfully saved the post",
          icon: "success",
          showConfirmButton: false,
          showCancelButton: false,
        }).then(() => {
          this.$emit('updated');
        })
      } catch {
        this.is_loading = false;
        await Swal.fire({
          title: 'Error',
          text: "Error posting news. Please try again.",
          icon: "error",
          showConfirmButton: true,
        })
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Add news" hide-footer centered size="lg">
    <template v-if="step === 1">
      <div v-if="images.length > 0" class="row mb-4 g-3">

        <div v-for="(img, index) in images.filter(i => !i.is_deleted)" :key="`img_${img.id}`" class="h-100" :class="{
        'col-12': index === 0,
        'col-6': index > 0,
      }">
          <div class="position-relative h-100">
            <img class="img-fluid h-100"
                 :src="img.url"
                 style="max-width: 100%; object-fit: cover; border-radius: 8px;"
                 alt="img"
            />
            <div class="position-absolute p-3" style="bottom: 0; right: 0">
              <b-button @click="deleteImage(index)" variant="danger" size="sm">
                <i class="mdi mdi-trash-can"></i> - {{ img.is_deleted }}
              </b-button>
            </div>
          </div>
        </div>

        <div :class="{
        'col-12': !isEven(images.filter(i => !i.is_deleted).length),
        'col-6': isEven(images.filter(i => !i.is_deleted).length),
      }">
          <div>
            <label class="form-label">Add photos</label>
            <input type="file" id="add_news_images" class="form-control" placeholder="Seal Image"
                   @change="handleFileUpload" multiple>
          </div>
        </div>

      </div>
      <div v-else>
        <label class="form-label">Select photos</label>
        <input type="file" id="add_news_images" class="form-control" placeholder="Seal Image"
               @change="handleFileUpload" multiple>
      </div>
      <div v-if="images.filter(i => !i.is_deleted).length > 0" class="text-center pt-4">
        <b-button @click="step = 2" variant="primary">
          Next
          <i class="mdi mdi-arrow-right ms-1"></i>
        </b-button>
      </div>
    </template>
    <template v-else-if="step === 2">
      <div class="row gy-4">

        <div class="col-12">
          <label for="news_title_en" class="form-label">Title
            <span class="text-muted">(English)</span>
          </label>
          <input v-model="title_en" :disabled="is_loading" type="text" class="form-control" id="news_title_en">
        </div>
        <div class="col-12">
          <label for="news_title_ru" class="form-label">Title
            <span class="text-muted">(Russian)</span>
          </label>
          <input v-model="title_ru" :disabled="is_loading" type="text" class="form-control" id="news_title_ru">
        </div>

        <div class="col-12">
          <label for="news_title_ru" class="form-label">Content
            <span class="text-muted">(English)</span>
          </label>
          <ckeditor v-model="content_en" :disabled="is_loading" ref="inquiry_text_editor" :editor="editor"></ckeditor>
        </div>
        <div class="col-12">
          <label for="news_title_ru" class="form-label">Content
            <span class="text-muted">(Russian)</span>
          </label>
          <ckeditor v-model="content_ru" :disabled="is_loading" ref="inquiry_text_editor" :editor="editor"></ckeditor>
        </div>

        <div class="col-12 text-center">
          <b-button @click="step = 1" variant="light" class="me-2" :disabled="is_loading">
            <i class="mdi mdi-arrow-left me-1"></i>
            Back
          </b-button>
          <b-button @click="submitForm()" variant="primary" :disabled="is_loading">
            {{ is_loading ? '' : 'Save now' }} <i class="mdi ms-1 align-middle" :class="{
              'mdi-loading mdi-spin': is_loading,
              'mdi-check-all': !is_loading,
            }"></i>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

</style>