<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import moment from 'moment';
import CreateNewsModal from "@/views/pages/inter_rail_uz/news/modals/Create.vue"
import UpdateNewsModal from "@/views/pages/inter_rail_uz/news/modals/Update.vue"
import DeleteNewsModal from "@/views/pages/inter_rail_uz/news/modals/Delete.vue"

const createNewsModal = ref(null);
const updateNewsModal = ref(null);
const deleteNewsModal = ref(null);

let searchQuery = ref('');

const BASE_URL = process.env.VUE_APP_ORDER_URL;
let news = ref([])

const getNews = async () => {
  try {
    let response = await axios.get('/website/posts/')
    news.value = (response.data.results || []).map((item) => {
      return {
        ...item,
        mainImg: (item.images || []).length > 0 ? {
          id: item.images[0].id,
          image: BASE_URL + item.images[0].image,
        } : null,
        modifiedAgo: moment(item.modified).fromNow(),
        modifiedDate: moment(item.modifiedText).format('lll'),
        createdDate: moment(item.created).format('lll'),
      }
    })
  } catch {
    alert("Failed to load news");
  }
}

const filteredNews = computed(() => {
  if (!searchQuery.value) return news.value;
  return news.value.filter((item) => {
    return item.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        item.title_ru.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        item.content.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        item.content_ru.toLowerCase().includes(searchQuery.value.toLowerCase())
  })
});

const cleanText = (html) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  return tempElement.textContent || tempElement.innerText;
}

onMounted(async () => {
  await getNews()
})
</script>

<template>

  <CreateNewsModal ref="createNewsModal" @created="getNews()"/>
  <UpdateNewsModal ref="updateNewsModal" @updated="getNews()"/>
  <DeleteNewsModal ref="deleteNewsModal" @deleted="getNews()"/>

  <div class="row mb-4 g-4">
    <div class="col-12 col-lg-2">
      <b-button
          @click="createNewsModal?.openModal()" variant="primary" class="w-100">+ Add New
      </b-button>
    </div>
    <div class="col-12 col-lg-10">
      <input v-model="searchQuery" class="form-control w-100" placeholder="Search">
    </div>
  </div>

  <div class="row mb-4 g-4">
    <template v-for="item in filteredNews" :key="`news_${item.id}`">
      <div class="col-lg-6 col-xl-4 col-xxl-3">
        <b-card no-body class="mb-4 h-100">
          <div class="position-relative">
            <img v-if="item.mainImg" class="card-img-top img-fluid"
                 :src="item.mainImg.image"
                 :alt="item.title"
                 style="height: 350px; width: 100%; object-fit: cover"
            />
            <img v-else class="card-img-top img-fluid"
                 src="@/assets/images/no_blog_img.png"
                 :alt="item.title"
                 style="height: 350px; width: 100%; object-fit: cover"
            />
            <div class="position-absolute top-0 w-100 p-2">
              <h5 class="mb-0">
                <span class="badge rounded-pill bg-light text-body">
                  {{ (item.images || []).length }}
                  {{ (item.images || []).length > 1 ? 'Photos' : 'Photo' }}
                </span>
              </h5>
            </div>
          </div>
          <b-card-body class="mb-0">
            <b-card-title class="pt-1">
              <small class="text-muted fw-normal">{{ item.createdDate }}</small>
              <p v-b-tooltip.hover :title="item.title_ru || ''" class="mb-0 lh-base mt-2 mb-3">
                {{ item.title }}
              </p>
              <small class="text-muted fw-normal mb-0 truncate-5-lines lh-base">
                {{ cleanText(item.content) }}
              </small>
            </b-card-title>
          </b-card-body>

          <b-card-footer class="mt-0">
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <h6 class="mb-0 text-muted">{{ item.modifiedAgo }}</h6>
                <small class="text-muted">Last updated</small>
              </div>
              <div class="d-flex gap-2">
                <BButton
                    @click="updateNewsModal.openModal(item)"
                    variant="soft-primary" class="waves-effect waves-light">
                  <i class="mdi mdi-pencil"></i>
                </BButton>
                <BButton
                    @click="deleteNewsModal?.openModal(item)"
                    variant="soft-danger" class="waves-effect waves-light">
                  <i class="mdi mdi-trash-can"></i>
                </BButton>
              </div>
            </div>
          </b-card-footer>
        </b-card>
      </div>
    </template>
    <template v-if="filteredNews.length === 0">
      <h3>No posts found</h3>
    </template>
  </div>
</template>

<style scoped>
.truncate-5-lines {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>